import React from 'react'
import styled from 'styled-components/macro'
import { Row } from '../../components/grid/Row'
import { Col } from '../../components/grid/Col'
import { color, sizes } from '../../theme'
import rem from 'polished/lib/helpers/rem'
import { Button } from '../../components/Button/Button'
import { navigate } from 'gatsby-link'
import { fontFamily } from '../../theme/typi'
import { Block } from '../../components/Block'
import { Link } from '../../components/Link'
import { Text } from '../../components/Text'
import { font } from '../../theme/mixins/font'

function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const FormularioContacto = () => {
  const [state, setState] = React.useState({ politica: null })

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const form = e.target

    if (state.politica !== 'si') {
      alert(
        'Para poder enviar el formulario debes aceptar la política de privacidad',
      )
      return
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate('/contacto-ok'))
      .catch(error => alert(error))
  }

  return (
    <form
      name="contacto"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contacto" />
      <Row align="center">
        <Col mobile={12} tablet={6}>
          <Input
            type="text"
            name="nombre"
            placeholder="Nombre"
            required
            onChange={handleChange}
          />
        </Col>
        <Col mobile={12} tablet={6}>
          <Input
            type="text"
            name="telefono"
            placeholder="Teléfono o email"
            required
            onChange={handleChange}
          />
        </Col>
        <Col mobile={12}>
          <Input
            as="textarea"
            name="peticion"
            rows={7}
            placeholder="¿Qué puedo hacer por ti?"
            required
            onChange={handleChange}
          ></Input>
        </Col>
        <Col mobile={12}>
          <Text size="xsmall" color="gray2" marginBottom="size4">
            Te informamos que los datos de carácter personal que nos
            proporcionas rellenando este formulario, serán tratados por Codés
            Cestau Apesteguia, como responsable de la web. La finalidad de la
            recogida y tratamiento de los datos personales que te solicitamos es
            para enviarte información sobre nuestros, productos y/o servicios.
            La legitimación se realiza a través del consentimiento que nos
            prestas con el envío de este formulario. El hecho de no facilitarnos
            los datos de carácter personal que aparecen en el formulario como
            obligatorias hará que no podamos atender tu solicitud. Puedes
            ejercer tus derechos de acceso, rectificación, limitación,
            portabilidad o supresión en codes@centrofeeling.es.
          </Text>
        </Col>
        <Col
          mobile={{ size: 12, justifySelf: 'center' }}
          tablet={{ size: 7, justifySelf: 'start' }}
        >
          <Block grow>
            <input
              id="politica"
              name="politica"
              type="checkbox"
              value="si"
              checked={state.politica === 'si'}
              onChange={handleChange}
            />
            <AceptoLabel htmlFor="politica">
              Acepto la{' '}
              <Link component="a" href="/politica-privacidad" target="_blank">
                política de privacidad
              </Link>
            </AceptoLabel>
          </Block>
        </Col>
        <Col
          mobile={{ size: 12, justifySelf: 'center' }}
          tablet={{ size: 5, justifySelf: 'end' }}
        >
          <Button type="submit" secondary>
            Enviar
          </Button>
        </Col>
      </Row>
    </form>
  )
}

const AceptoLabel = styled.label`
  ${font.small};
  margin-left: ${rem(sizes.size2)};
`

const Input = styled.input`
  border: 1px solid ${color.gray2};
  padding: ${rem(sizes.size4)};
  ${font.base};
  font-family: ${fontFamily.roboto};
  width: 100%;
`
