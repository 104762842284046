import React from 'react'
import { Layout } from '../containers/Layout'
import { Text } from '../components/Text'
import { Container } from '../components/grid/Container'
import { FormularioContacto } from '../partials/contacto/FormularioContacto'
import { Stack } from '../components/Stack'
import { Icon } from '../components/Icon/Icon'
import PhoneSvg from '../images/icon/phone.svg'
import WhatsappSvg from '../images/icon/whatsapp.svg'
import { ContactLink } from '../partials/ContactLink'
import { MobileContent } from '../components/grid/MobileContent'
import { Mapa } from '../partials/index/Mapa'
import { Block } from '../components/Block'
import { Separator } from '../components/Separator'
import styled from 'styled-components/macro'
import { rem } from 'polished'
import { Seo } from '../partials/Seo'

function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Page = () => {
  return (
    <Layout fullWidth>
      <Seo title="Contacto - Centro Feeling" />
      <Container marginBottom="size9">
        <MobileContent>
          <Text size="h1" family="merienda" center>
            ¿Puedo ayudarte?
          </Text>
          <Separator />
          <Block
            flex
            justify="center"
            marginBottom="size5"
            tablet={{ marginBottom: 'size7' }}
          >
            <Stack
              horizontal="size5"
              tablet={{ horizontal: 'size7' }}
              justify="center"
            >
              <ContactLink
                href="https://api.whatsapp.com/send?phone=+34699762837"
                target="_blank"
              >
                <Icon
                  size="medium"
                  tablet={{ size: 'large' }}
                  marginBottom="size2"
                  component={<WhatsappSvg />}
                />
                <Text
                  tag="span"
                  size="base"
                  tablet={{ size: 'large' }}
                  weight="bold"
                >
                  699 762 837
                </Text>
              </ContactLink>
            </Stack>
          </Block>

          <FormularioWrapper>
            <FormularioContacto />
          </FormularioWrapper>
        </MobileContent>
      </Container>
      <Mapa />
    </Layout>
  )
}

const FormularioWrapper = styled.div`
  max-width: ${rem(700)};
  margin-left: auto;
  margin-right: auto;
`

export default Page
